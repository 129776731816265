import {Route, Switch} from "react-router-dom";
import React from "react";
import { LandingApp } from "../components/MainPage/MainPage";
import { AboutPage } from "../components/AboutPage/AboutPage";



export const Routes = () => (
    <Switch>
        <Route exact path="/" component={LandingApp} />
        <Route exact path="/about" component={AboutPage} />
    </Switch>
);