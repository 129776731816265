import React from 'react'
import PropTypes from 'prop-types'
import styled, { keyframes } from 'styled-components'

import { NavbarHome } from '../SectionHeader/SectionHeader'

import MainImage from '../../assets/images/main/main-image.png'
import CoachCircleSvg from '../../assets/images/main/coach-circle.svg'
import CataclystCicrleSvg from '../../assets/images/main/catalyst-circle.svg'
import DecorItemSvg from '../../assets/images/main/decor-item.svg'
import ConsultantCircleSvg from '../../assets/images/main/consultant-circle.svg'
import ArrowDownSvg from '../../assets/images/main/arrow-down.svg'
import { MainHeading } from '../Shared/MainHeading'
import { device } from '../../constants/mediaQueries'

const Main = styled.section`
    min-height: 100vh;
    background: #9B9B9B;
    position: relative;
    overflow: hidden;
    background-image: url(${ArrowDownSvg});
    background-position: center bottom 20px;
    background-repeat: no-repeat;
`

const rotate = keyframes`
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
`;


const ContentWrapper = styled.div`
    display: flex;
    align-items: center;
    justify-content: flex-start;

    margin-left: 70px;
    margin: auto auto;
    @media all and (max-width: 767px) {
        margin: auto auto;

        flex-direction: column;
        align-items: flex-start;
    }
`

const MainImageWrapper = styled.img`
    height: 300px;
    padding: 20px;
    display: none;
    @media ${device.laptopL} {
        display: block;
    }   
`

const AnimatedBackgroundImageContainer = styled.img`
    position: absolute;
    margin: 0 0;
    animation: ${rotate} 113s linear infinite;
    display: none;

    @media ${device.laptop} {
        display: block;
    }
`

const CataclystCicrleSvgWrapper = styled(AnimatedBackgroundImageContainer)`
    bottom: 20px;
    right: 20px;

    @media ${device.laptop} {
        height: 244px;
    }
    @media ${device.laptopL} {
        height: auto;
    }
`

const CoachCicrleSvgWrapper = styled(AnimatedBackgroundImageContainer)`
    top: 200px;
    left: -200px;
    
    @media ${device.laptop} {
        height: 511px;
    }
    @media ${device.laptopL} {
        height: auto;
    }
`

const ConsultCicrleSvgWrapper = styled(AnimatedBackgroundImageContainer)`
    right: 200px;
    
    @media ${device.laptop} {
        height: 292px;
    }
    @media ${device.laptopL} {
        height: auto;
    }
`

export const MainSection = ({ title, mainImage }) => (
    <Main>
        <NavbarHome></NavbarHome>
        <CataclystCicrleSvgWrapper src={CataclystCicrleSvg} />
        <ConsultCicrleSvgWrapper src={ConsultantCircleSvg} />

        <CoachCicrleSvgWrapper src={CoachCircleSvg} />

        <ContentWrapper>

            <MainImageWrapper src={DecorItemSvg} />
            <MainHeading>{title}</MainHeading>
        </ContentWrapper>
    </Main>
)

MainSection.propTypes = {
    title: PropTypes.string.isRequired,
    mainImage: PropTypes.string.isRequired
}

MainSection.defaultProps = {
    title: 'Appletree Innovationcy',
}