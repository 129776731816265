import styled from 'styled-components'
import { device } from '../../constants/mediaQueries'

export const MainHeading = styled.h1`
    font-family: Work Sans;
    font-style: normal;
    font-weight: 500;
    line-height: 100%;
 
    white-space: pre-wrap;
    letter-spacing: -0.03em;
    color: #FFFFFF;
    text-align: left;
    margin: 0;

    @media ${device.mobileS} {
        font-size: 2em;
    }

    @media ${device.mobileL} { 
        font-size: 3em;
     }

    @media ${device.tablet} { 
        font-size: 4em;
    } 

    @media ${device.laptopL} { 
        font-size: 5em;
     } 
    
`