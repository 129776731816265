import React from 'react';
import { BrowserRouter as Router } from "react-router-dom";
import { Routes } from "../../router/Routes";


const App = () => (
    <Router>
        <Routes />
    </Router>
);

export default App;
