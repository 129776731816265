import React from "react"
import styled from "styled-components"
import PropTypes from "prop-types"

import { Navbar, Nav, Form } from 'react-bootstrap'

import Logo from '../../assets/images/logo.svg'
import LogoDark from '../../assets/images/logo-black.svg'
import LogoGreen from '../../assets/images/logo-green.svg'
import ArrowRight from '../../assets/images/arrow-right.svg'
import ArrowRightWhite from '../../assets/images/arrow-right-white.svg'
import { device } from "../../constants/mediaQueries"

const HeaderWrapper = styled.div`   
     
    position: ${props => props.position ? props.position : "relative"};
    display: flex;
    justify-content: space-between;
    padding: 10px;

    @media ${device.tablet} {
        padding: 40px;
    }

`;

const ButtonCustom = styled.div`
    display: none;
    padding: 15px 20px;
    justify-content: space-between;
    align-items: center;
    /* display: flex; */
    /* width: 150px; */
    /* height: 72px; */
    border-radius: 100px;
    background: #FFF;
    
    
    p {
        margin: 0 0;
        margin-right: 25px;
        font-style: normal;
        font-weight: 500;
        font-size: 1em;
        line-height: 156%;
        letter-spacing: -0.02em;
  
        color: #000000;

        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
   }
   
   @media only screen and (max-width: 525px) {
      width: 25px;
      height: 40px;
      margin-left: 5px;
      justify-content: center;
      padding-left: 5px;
      padding-right: 10px;

      p {
        display: none;
        font-size: 14px;
        margin-left: 5px;
      }
   }
`

const NavLinkContainer = styled.div`
    a {
        border-right: 1px solid rgba(0,0,0,0.25);

    }
    a:last-child {
        border-right: none;

    }
`

const NavLink = styled.a`
    padding: 0 20px;
`

const NavLinkNotSelected = styled(NavLink)`
    color: rgba(0, 0, 0, 0.25);
`

const NavLinkAboutSelected = styled(NavLink)`
    color: black;
`

const NavLinkHomeSelected = styled(NavLink)`
    color: #BEE900;
`
const ButtonDark = styled(ButtonCustom)`
    background: #2D127A;
    p {
        color: #FFF;
    }
`

const LogoWrapper = styled.img`
        height: 50px;
        @media only screen and (max-width: 525px) {
            height: 45px;
            width: auto;
        }
`



export const NavbarHome = () => <Navbar expand="lg">
<Navbar.Brand href="/">
    <LogoWrapper src={Logo} className="d-inline-block align-top">

    </LogoWrapper>
</Navbar.Brand>
<Navbar.Toggle aria-controls="basic-navbar-nav" />
<Navbar.Collapse id="basic-navbar-nav">
    <Nav className="mr-auto">
        <NavLinkContainer>
        <NavLinkHomeSelected href="/">Home</NavLinkHomeSelected>
        <NavLinkNotSelected href="/about">About</NavLinkNotSelected>
        </NavLinkContainer>
    </Nav>
    <Form inline>
        <ButtonDark><p>E-Learning </p> <img src={ArrowRightWhite} alt="" /></ButtonDark>

    </Form>
</Navbar.Collapse>
</Navbar>

export const NavbarAbout = () => <Navbar expand="lg">
    <Navbar.Brand href="/">
        <LogoWrapper src={LogoDark} className="d-inline-block align-top">

        </LogoWrapper>
    </Navbar.Brand>
    <Navbar.Toggle aria-controls="basic-navbar-nav" />
    <Navbar.Collapse id="basic-navbar-nav">
        <Nav className="mr-auto">
            <NavLinkContainer>
            <NavLinkNotSelected href="/">Home</NavLinkNotSelected>
            <NavLinkAboutSelected href="/about">About</NavLinkAboutSelected>
            </NavLinkContainer>
        </Nav>
        <Form inline>
            <ButtonDark><p>E-Learning </p> <img src={ArrowRightWhite} alt="" /></ButtonDark>
    
        </Form>
    </Navbar.Collapse>
</Navbar>

const SectionHeaderBase = (apperance) => ({ text, position }) => (
    <HeaderWrapper position={position}>

        {apperance === "grey" && <div><LogoWrapper src={Logo} alt="" /></div>}
        {apperance === "green" && <div><LogoWrapper src={LogoGreen} alt="" /></div>}
        {apperance === "dark" && <div><LogoWrapper src={LogoDark} alt="" /></div>}

        <LearningButton text={text} />
    </HeaderWrapper>
)

const LearningButton = ({ text }) => (<ButtonCustom>
    <p>{text}</p><img src={ArrowRight} alt="" />
</ButtonCustom>)

export const SectionHeader = SectionHeaderBase("grey");
export const SectionHeaderGreen = SectionHeaderBase("green");
export const SectionHeaderDark = SectionHeaderBase("dark");

SectionHeader.propTypes = {
    text: PropTypes.string.isRequired
}


