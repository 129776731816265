import React, { useState, useEffect } from 'react';
import styled from 'styled-components';

import { FooterSection } from '../Footer/Footer';
import { NavbarAbout } from '../SectionHeader/SectionHeader';
import { Heading1, Heading2, Text, Heading3 } from '../Shared/Typography';

import landingMockUp from '../../assets/landing-data.json'

import BorreCoachPng from '../../assets/images/about/borre-coach.png'

import { device } from '../../constants/mediaQueries';

const Container = styled.div`
    padding: 25px;
`

const Main = styled.main`
    max-width: 1700px;
    display: flex;
    flex-direction: column;
    margin: 0 auto;

    @media ${device.laptop} {
        padding: 10px 120px;

        flex-direction: column;

    }
`

const Wrapper = styled.div`
    display: flex;
    width: 100%;
    flex-direction: column;
    @media ${device.laptop} {
        flex-direction: row;
    }

`

const RowWrapper = styled(Wrapper)`
    padding: 30px 0;
    display: flex;
    width: 100%;
    flex-direction: column; 
    @media ${device.laptop} {
        flex-direction: row;
    }
`

const ColumnWrapper = styled(Wrapper)`
    display: flex;
    flex-direction: column; 
    width: 100%;
    @media ${device.laptop} {
        flex-direction: column;
    }
`

const MainTitle = styled(Heading1)`
    padding-bottom: 40px;
`

const GreyText = styled(Text)`
    color: #808080;
`

const BoldText = styled(Text)`
    font-weight: 500;
`

const BorreCoachImg = styled.img`
    display: none;
    @media ${device.laptop} {
        display: flex;
        height: 500px;
        margin-left: auto;
    }
`

const WorkExperinceTitle = styled(Heading2)`
    padding-top: 50px;
    text-align: center;
    margin: 0 auto;
`

const WorkExperinceSubTitle = styled(GreyText)`
    text-align: center;
    margin: 0 auto;
    padding-top: 10px;
    padding-bottom: 50px;
`

export const AboutPage = () => {

    const [landing, setLanding] = useState(landingMockUp)

    useEffect(() => {
        // temp way to strapi
        const getLanding = async () => {
            const response = await fetch(` https://cms.apl3i.com/landings`)

            setLanding((await response.json())[0] || landing)
        }
        getLanding()
    }, [])

    return <Container>
        <NavbarAbout />
        <Main>
            <RowWrapper>
                <ColumnWrapper>
                    <MainTitle>
                        {landing.aboutPage.mainTitle}
                    </MainTitle>
                    <GreyText>
                        {landing.aboutPage.mainDescription}
                    </GreyText>
                </ColumnWrapper>
                <Wrapper>
                    <BorreCoachImg src={BorreCoachPng} />
                </Wrapper>
            </RowWrapper>
            <ColumnWrapper>
                <BoldText>He has low (1 year) to medium (1-1/2 - 2 year) degrees in </BoldText>
                <Wrapper>
                    <Wrapper>
                        <GreyText>{landing.aboutPage.lowToMediumDegreesFirst}</GreyText>
                    </Wrapper>
                    <Wrapper>
                        <GreyText>{landing.aboutPage.lowToMediumDegreesSecond}</GreyText>
                    </Wrapper>
                </Wrapper>
            </ColumnWrapper>

            <ColumnWrapper>
                <WorkExperinceTitle>{landing.aboutPage.workExperienceTitle}</WorkExperinceTitle>
                <WorkExperinceSubTitle>
                    {landing.aboutPage.workExperienceSubtitle}
                </WorkExperinceSubTitle>
            </ColumnWrapper>
            <RowWrapper>
                <ColumnWrapper>
                    <Text>
                        Hamburg/global (the last 5 years)
                </Text>
                    <GreyText>{landing.aboutPage.globalWorkExperience}</GreyText>
                </ColumnWrapper>

                <ColumnWrapper>
                    <Text>
                        Norway (22 years - then move to Hamburg)

                    </Text>
                    <GreyText>
                        {landing.aboutPage.norwayWorkExperience}

                    </GreyText>

                </ColumnWrapper>
            </RowWrapper>
            <RowWrapper>
                <ColumnWrapper>
                    <Text>
                        SPORT - specialist
                </Text>
                    <GreyText>{landing.aboutPage.sportWorkExperience}
                    </GreyText>
                </ColumnWrapper>
            </RowWrapper>
            <ColumnWrapper>
                <Heading3>Update</Heading3>

            </ColumnWrapper>
            <RowWrapper>
                <GreyText>
                    {landing.aboutPage.updateText}
                </GreyText>


            </RowWrapper>
            <FooterSection
                fbLink={landing.motivation.fbLink}
                xingLink={landing.motivation.xingLink}
                linkedinLink={landing.motivation.linkedinLink}
                contactAddress={landing.footer.contactAddress}
                contactEmail={landing.footer.contactEmail}
                contactPhoneNumber={landing.footer.contactPhoneNumber}
            />
        </Main>
    </Container>
}