import React from 'react'
import styled from 'styled-components'
import PropTypes from 'prop-types'
import BackgroundBoatPng from '../../assets/images/achievements/boat.png'
import BackgroundLogoSvg from '../../assets/images/achievements/background-logo-grey.svg'
import CataclystCircleSvg from '../../assets/images/achievements/cataclyst-circle.svg'
import { SectionHeader } from '../SectionHeader/SectionHeader'
import { device } from '../../constants/mediaQueries'

const Achievements = styled.section`

    min-height: 100vh;

    background: #9B9B9B;
    @media (min-width: 1000px) {
        background-image: url(${CataclystCircleSvg}), url(${BackgroundLogoSvg}), url(${BackgroundBoatPng});
        background-repeat: no-repeat;
        background-position: right  top -70px, left 50px bottom 100px, right 70px top 70px;
        background-size: 210px, 100px, 390px;  
    }
`;

const ContentWrapper = styled.div`
    margin: auto auto;
    margin-top: 70px;
    width: 95vw;
    max-width: 768px;

    @media ${device.tablet} {
        margin: auto auto;
    }
    @media ${device.laptopL} {
        max-width: 1024px;
        
    }

`;

const TitleWrapper = styled.p`
    margin: 0px 0px;
    font-family: Work Sans;
    font-style: normal;
    font-size: 5em;
    font-weight: 500;

    letter-spacing: -0.03em;
    color: #FFFFFF;
    @media all and (max-width: 1199px) { 
        font-size: 4em;
     } 
    @media all and (max-width: 768px) { 
        font-size: 3em;
    } 
    @media all and (max-width: 575px) { 
        font-size: 2em;
     }
`;

const AchievementsCountersWrapper = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    @media all and (max-width: 767px) {
        margin: 1em auto;
        justify-content: center;
        flex-direction: column;
        p {
            text-align: center;
        }
    }
`;

const AchievementCounterWrapper = styled.div``;

const AchievementCounterTitle = styled.p`
    font-family: Work Sans;
    font-style: normal;
    font-weight: normal;
    font-size: 0.9em;
  
    color: #CEEB03;


`;

const AchievementCounterValue = styled.p`
    margin: 0 0;
    font-family: Work Sans;
    font-size: 6em;
    font-weight: 500;
    line-height: 107%;
    letter-spacing: -0.03em;
    color: #FFFFFF;
    @media all and (max-width: 768px) { 
        font-size: 4em;
     } 
     @media all and (max-width: 425px) { 
        font-size: 3em;
     } 
`;

const SubTitleWrapper = styled.p`

    font-family: Work Sans;
    font-style: normal;
    font-weight: normal;
    font-size: 1.5em;

    letter-spacing: -0.05em;
    color: rgba(255, 255, 255, 0.8);
    @media all and (max-width: 1000px) { 
        font-size: 1em;
     } 

`;



const AchievementCounterComponent = ({achievement}) => (
    <AchievementCounterWrapper>
        <AchievementCounterValue>{achievement.value}</AchievementCounterValue>
        <AchievementCounterTitle>{achievement.title}</AchievementCounterTitle>
    </AchievementCounterWrapper>
);

const AchievementShape = {
    value: PropTypes.integer,
    title: PropTypes.string
};

AchievementCounterComponent.propTypes = {
    achievement: AchievementShape
};

export const AchievementsSection = ({ title, subTitle, firstAchievement, secondAchievement, thirdAchievement }) => (
    <Achievements>
        <SectionHeader position="absolute" text={'E-learning'}/>
        <ContentWrapper> 
            <TitleWrapper>{title}</TitleWrapper>
            <SubTitleWrapper>{subTitle}</SubTitleWrapper>
            <AchievementsCountersWrapper>
                <AchievementCounterComponent achievement={firstAchievement} />
                <AchievementCounterComponent achievement={secondAchievement} />
                <AchievementCounterComponent achievement={thirdAchievement} />
            </AchievementsCountersWrapper>

        </ContentWrapper>
    </Achievements>
);

AchievementsSection.propTypes = {
    title: PropTypes.string.isRequired,
    subTitle: PropTypes.string.isRequired,
    firstAchievement: AchievementShape.isRequired,
    footerText: PropTypes.string.isRequired
};

AchievementsSection.defaultProps = {
    title: 'Achievements',
    subTitle: 'I have tools and techniques that stem from research - experience and my own work',
    firstAchievement: {
        title: 'INDIVIDUAL PROJECTS',
        value: 133
    },
    secondAchievement: {
        title: 'TEAM PROJECTS',
        value: 254
    },
    thirdAchievement: {
        title: 'CORPORATE PROJECTS',
        value: 100
    },
    footerText: 'Ambassadors, CEO’s, to professional footballers or any type of managers'
};
