import React from 'react'
import styled from 'styled-components'

import JanAvatarPng from '../../assets/images/review/jan-avatar.png'
import EmptyAvatarPng from '../../assets/images/review/empty-avatar.png'
import ArrowLeftGreySvg from '../../assets/images/arrow-left-grey.svg'
import ArrowRightBlackSvg from '../../assets/images/arrow-right.svg'
import { device } from '../../constants/mediaQueries'

const Container = styled.div`
    display: flex;
`

const Wrapper = styled.div`
    margin: 50px auto;
    width: 95vw;
    max-width: 768px;
    
    @media ${device.laptopL} {
        max-width: 1024px;
        
        background-image: url(${ArrowLeftGreySvg}), url(${ArrowRightBlackSvg});
        background-repeat: no-repeat;
        background-size: 23px, 23px;
        background-position: left center, right center;
    }

`

const MainTitle = styled.p`

    font-weight: 500;
    font-size: 2em;
    text-align: center;
    line-height: 143%;
    @media ${device.laptop} {
        font-size: 3em;
    }
`

const ReviewWrapper = styled.div`
`

const ReviewText = styled.p`
    letter-spacing: -0.05em;

    text-align: center;
    font-size: 1em;
    @media ${device.laptop} {
        font-size: 1.5em;
    }
`

const ReviewAuthor = styled(ReviewText)`
    color: #350380;
`;

const ReviewAvatarsWrapper = styled.div`
    display: flex;
    justify-content: center;
`;

const ReviewAvatarOther = styled.img`
    margin: auto 10px;
    display: none;

    @media ${device.tablet} {
        display: block;
    }
`;

const ReviewAvatarCurrent = styled.img`
    margin: auto 20px;
`;

export const ReviewSection = ({ firstReview }) => {

    const reviewText = `Borre opens a path of growth for people through brutal acts of highlighting
corners of our own ego and using it as an exceptional leverage to initiate
deep personal and group-centric self-reflection`

    return <Container>
        <Wrapper>
            <MainTitle>Satisfied customers</MainTitle>
            <ReviewWrapper>
                <ReviewText>
                    {firstReview.text}
                </ReviewText>
                <ReviewAuthor>
                    {firstReview.author}
            </ReviewAuthor>
                <ReviewAvatarsWrapper>
                    <ReviewAvatarOther src={EmptyAvatarPng} />
                    <ReviewAvatarOther src={EmptyAvatarPng} />
                    <ReviewAvatarCurrent src={JanAvatarPng} />
                    <ReviewAvatarOther src={EmptyAvatarPng} />
                    <ReviewAvatarOther src={EmptyAvatarPng} />
                </ReviewAvatarsWrapper>
            </ReviewWrapper>
        </Wrapper>

    </Container>
}