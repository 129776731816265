import styled from 'styled-components'
import React from 'react'
import PropTypes from 'prop-types'

const BenefitWrapper = styled.div`
    margin: 0 0;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    @media all and (max-width: 767px) { 
        margin: 0.5em 0 0;  
    } 
`

const BenefitTitle = styled.p`
    margin: 0 0;
    font-family: Work Sans;
    font-style: normal;
    font-weight: 500;
    font-size: 2.5em;
    letter-spacing: -0.03em;
    color: #000000;
    @media all and (max-width: 1199px) { 
        font-size: 2em;
     } 
    @media all and (max-width: 767px) { 
        text-align: center;
    } 
`

const BenefitHeader = styled.div`
    margin: 0 0;
    display: flex;    
    @media all and (max-width: 767px) { 
        justify-content: center;    
    } 
`

const BenefitHeaderNumber = styled.p`
    margin: 0 0;
    font-family: Work sans;
    font-size: 6em;
    letter-spacing: -0.03em;
    @media all and (max-width: 1000px) { 
        font-size: 5em;
     } 
     @media all and (max-width: 767px) { 
        display: none; 
    } 
`

const BenefitImage = styled.img`
    margin-left: 15px;
    align-self: center;
    width: 4em;
    height: auto;
    @media all and (max-width: 768px) { 
        width: 3em;
     } 
`

const BenefitSubTitle = styled.p`
    font-family: Work Sans;
    font-style: normal;
    font-weight: normal;
    font-size: 1.5em;
    letter-spacing: -0.05em;
    color: #808080;
    @media all and (max-width: 1199px) { 
        font-size: 1em;
     } 
     @media all and (max-width: 767px) { 
        text-align: center;
    } 
`

export const BenefitComponent = ({ title, subTitle, img, number }) => (
    <BenefitWrapper>
        <BenefitHeader>
            <BenefitHeaderNumber>{number}</BenefitHeaderNumber>
            <BenefitImage src={img} />
        </BenefitHeader>
        <BenefitTitle>{title}</BenefitTitle>
        <BenefitSubTitle>{subTitle}</BenefitSubTitle>
    </BenefitWrapper>
)

BenefitComponent.propTypes = {
    title: PropTypes.string.isRequired,
    subTitle: PropTypes.string.isRequired,
    number: PropTypes.string.isRequired,
    img: PropTypes.string.isRequired
}
