import React from 'react'
import styled from 'styled-components'
import PropTypes from 'prop-types'

import FbIconSvg from '../../assets/images/footer/fb.svg'
import XingIconSvg from '../../assets/images/footer/xing.svg'
import LinkedinIconSvg from '../../assets/images/footer/linkedin.svg'


const currentYear = new Date().getFullYear()

const Container = styled.div`
    display: flex;
    width: 100%;
`

const Footer = styled.div`
    margin: 0 auto;
    width: 95vw;
`

const BasicText = styled.p`
    font-family: Work Sans;
    font-size: 0.9em;
`

const ContactsWrapper = styled.div`
    display: flex;
    justify-content: space-between;
    margin-bottom: 40px;
    @media all and (max-width: 1024px) {
        flex-direction: column;
        align-items: flex-end;
        p {
            text-align: right;
        }
        
    }
`

const SocialTitle = styled.p`
    font-family: Work Sans;
    font-size: 1em;
    font-weight: 500;
`

const SocialWrapper = styled.div`

`
const SocialButtonLink = styled.a`
    margin-right: 5px;
`
const SocialButtonImg = styled.img`
    width: 25%;
`
const SocialButtonsWrapper = styled.div`
    @media all and (max-width: 1024px) {
        text-align: right;
    }
`
const SocialButton = ({ link, img }) => (
    <SocialButtonLink href={link}>
        <SocialButtonImg src={img} />
    </SocialButtonLink>
)

const ContactWrapper = styled.div`
`

const ContactText = styled(BasicText)`
    text-align: right;
`
const ContactTextTitle = styled(SocialTitle)`
    text-align: right;
`

const Splitter = styled.div`
    height: 1px;
    background-color: #C4C4C4;
    margin-bottom: 43px;
`

export const FooterSection = ({ fbLink, xingLink, linkedinLink, contactPhoneNumber, contactAddress, contactEmail }) => (
    <Container>
        <Footer>
            <Splitter />
            <ContactsWrapper>
                <SocialWrapper>
                    <SocialTitle>Follow</SocialTitle>
                    <SocialButtonsWrapper>
                        <SocialButton img={FbIconSvg} link={fbLink} />
                        <SocialButton img={XingIconSvg} link={xingLink} />
                        <SocialButton img={LinkedinIconSvg} link={linkedinLink} />
                    </SocialButtonsWrapper>
                </SocialWrapper>
                <ContactWrapper>
                    <ContactTextTitle>Contact</ContactTextTitle>
                    <ContactText>{contactPhoneNumber}</ContactText>
                    <ContactText>{contactEmail}</ContactText>
                    <ContactText>{contactAddress}</ContactText>
                </ContactWrapper>
            </ContactsWrapper>
            <Splitter />
            <BasicText>© {currentYear} Appletree Innovationcy</BasicText>
        </Footer>
    </Container>
)

FooterSection.propTypes = {
    fbLink: PropTypes.string.isRequired,
    xingLink: PropTypes.string.isRequired,
    linkedinLink: PropTypes.string.isRequired,
    contactPhoneNumber: PropTypes.string.isRequired,
    contactAddress: PropTypes.string.isRequired,
    contactEmail: PropTypes.string.isRequired
}

FooterSection.defaultProps = {
    fbLink: "https://www.facebook.com/",
    xingLink: "https://www.xing.com",
    linkedinLink: "https://www.linkedin.com/",
    contactPhoneNumber: "+49 170 1555055",
    contactAddress: "2736 Nutters Barn Lane",
    contactEmail: "Info@apl3i.com"
}