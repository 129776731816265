import styled from 'styled-components'
import { device } from '../../constants/mediaQueries'

const mainHeadingStyles = `
    font-family: Work Sans;
    font-style: normal;
    font-weight: 500;

    white-space: pre-wrap;
    letter-spacing: -0.03em;
    text-align: left;
    margin: 0;
`;

const baseHeadingSize = {
    mobileS: 2,
    mobileL: 3,
    tablet: 4,
    laptopL: 5,
};

export const Heading1 = styled.h1`

    ${mainHeadingStyles}
    line-height: 100%;
     
    @media ${device.mobileS} {
    font-size: ${baseHeadingSize.mobileS}em;
    }

    @media ${device.mobileL}{ 
    font-size: ${baseHeadingSize.mobileL}em;
     }

    @media ${device.tablet} { 
        font-size: ${baseHeadingSize.tablet}em;
    } 

    @media ${device.laptopL} { 
        font-size: ${baseHeadingSize.laptopL}em;
     } 
    
`

export const Heading2 = styled.h2`

    ${mainHeadingStyles}

    @media ${device.mobileS} {
        font-size: ${baseHeadingSize.mobileS * 0.7}em;
    }

    @media ${device.mobileL}{ 
        font-size: ${baseHeadingSize.mobileL * 0.7}em;
     }

    @media ${device.tablet} { 
        font-size: ${baseHeadingSize.tablet * 0.7}em;
    } 

    @media ${device.laptopL} { 
        font-size: ${baseHeadingSize.laptopL * 0.7}em;
     } 
`

export const Heading3 = styled.h3`

    ${mainHeadingStyles}

    @media ${device.mobileS} {
        font-size: ${baseHeadingSize.mobileS * 0.6}em;
    }

    @media ${device.mobileL}{ 
        font-size: ${baseHeadingSize.mobileL * 0.6}em;
     }

    @media ${device.tablet} { 
        font-size: ${baseHeadingSize.tablet * 0.6}em;
    } 

    @media ${device.laptopL} { 
        font-size: ${baseHeadingSize.laptopL * 0.6}em;
     } 
`

export const Text = styled.p`
    font-style: normal;
    font-weight: normal;
    font-size: 1.5em;
    line-height: 125%;
    
    white-space: pre-wrap;

    letter-spacing: -0.03em;

    @media all and (max-width: 767px) { 
        max-width: 100%;
        font-size: 1em;
     } 
`;