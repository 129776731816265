import React, { useEffect, useState } from 'react'
import styled from 'styled-components';

import { MainSection } from './MainSection'
import { MotivationSection } from './MotivationSection'
import { AchievementsSection } from './AchievementsSection'
import { BenefitsSection } from './BenefitsSection'
import { FooterSection } from '../Footer/Footer'
import { ReviewSection } from './ReviewSection'

import MainImage from '../../assets/images/main/main-image.png'

import { device } from '../../constants/mediaQueries';

import landingMockUp from '../../assets/landing-data.json'


const toAchivementShape = (title, value) => ({ title, value })
const toBenefitShape = (title, subTitle) => ({ title, subTitle })

export const LandingApp = () => {

  const [landing, setLanding] = useState(landingMockUp)

  useEffect(() => {
    // temp way to strapi
    const getLanding = async () => {
      const response = await fetch(` https://cms.apl3i.com/landings`)
      setLanding((await response.json())[0])

    }
    getLanding()
  }, [])

  if (false) return (<>
    <MainSection title="Under Construction" mainImage="" />
  </>)

  const FooterSectionWrapper = styled.div`
    margin: auto auto;
    width: 95vw;
    max-width: 768px;
    @media ${device.laptopL} {
        max-width: 1024px;
        
    }
  `

  return (
    <>

      <MainSection title={landing.main.title} mainImage={MainImage} />
      <MotivationSection textLeft={landing.motivation.textLeft} textRight={landing.motivation.textRight} title={landing.motivation.title} />
      <BenefitsSection
        benefitsTitle={landing.benefits.benefitsTitle}
        mainTitle={landing.benefits.mainTitle}
        textTopLeft={landing.benefits.textTopLeft}
        textTopRight={landing.benefits.textTopRight}
        textDownRight={landing.benefits.textDownRight}
        firstBenefit={toBenefitShape(landing.benefits.firstBenefitTitle, landing.benefits.firstBenefitText)}
        secondBenefit={toBenefitShape(landing.benefits.secondBenefitTitle, landing.benefits.secondBenefitText)}
        thirdBenefit={toBenefitShape(landing.benefits.thirdBenefitTitle, landing.benefits.thirdBenefitText)}
      />
      <AchievementsSection
        secondAchievement={toAchivementShape(landing.achievements.thirdAchievementTitle, landing.achievements.thirdAchievementValue)}
        firstAchievement={toAchivementShape(landing.achievements.firstAchievementTitle, landing.achievements.firstAchievementValue)}
        thirdAchievement={toAchivementShape(landing.achievements.secondAchievementTitle, landing.achievements.secondAchievementValue)}
        title={landing.achievements.title}
        subTitle={landing.achievements.subTitle}
        footerText={landing.achievements.footerText}
      />
      <ReviewSection firstReview={landing.reviews[0]}/>
      <FooterSectionWrapper>
        <FooterSection
          fbLink={landing.motivation.fbLink}
          xingLink={landing.motivation.xingLink}
          linkedinLink={landing.motivation.linkedinLink}
          contactAddress={landing.footer.contactAddress}
          contactEmail={landing.footer.contactEmail}
          contactPhoneNumber={landing.footer.contactPhoneNumber}
        />
      </FooterSectionWrapper>

    </>)
}