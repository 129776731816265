import React from 'react'
import styled from 'styled-components'
import PropTypes from 'prop-types'

import { SectionHeaderDark } from '../SectionHeader/SectionHeader'
import { BenefitComponent } from './BenefitComponent'
import { MainHeading } from '../Shared/MainHeading'

import UpdateImg from '../../assets/images/benefits/update.png'
import UpgradeImg from '../../assets/images/benefits/upgrade.png'
import InnovateImg from '../../assets/images/benefits/innovate.png'
import LogoSvg from '../../assets/images/benefits/logo-big.svg'

import { device } from '../../constants/mediaQueries'

const Benefits = styled.section`
`

const ContentWrapper = styled.div`
    margin: 0 auto;
    width: 95vw;
    max-width: 768px;

    @media ${device.tablet} {
    }

    @media ${device.laptopL} {
        max-width: 1024px;
        
    }
`

const Wrapper = styled.div`

    margin-bottom: 20px;

    display: flex;
    justify-content: space-between;
    
    @media all and (max-width: 767px) {
        flex-direction: column;
    }
`

const ColumnWrapper = styled(Wrapper)`
    display: flex;
    justify-content: center;
    text-align: center;
    flex-direction: column;
`

const BenefitsWrapper = styled(Wrapper)`
    @media (max-width: 767px) {
        flex-direction: column;
        justify-content: flex-start;
        align-self: flex-start;
    }

    margin: 2em 0;
`

const MainTitle = styled(MainHeading)`
    margin: 0 0;
    color: #000000;
`

const BenefitsTitle = styled(MainTitle)`
    text-align: center;

    @media ${device.mobileS} {
        font-size: ${2 * 0.7}em;
    }

    @media ${device.mobileL}{ 
        font-size: ${3 * 0.7}em;
    }

    @media ${device.tablet} { 
        font-size: ${4 * 0.7}em;
    } 

    @media ${device.laptopL} { 
        font-size: ${5 * 0.7}em;
    } 
`

const SubTitle = styled.p`
    font-family: Work Sans;
    font-style: normal;
    font-weight: normal;
    font-size: 1.5em;

    letter-spacing: -0.05em;
    color: #808080;

    @media all and (max-width: 767px) { 
        max-width: 100%;
        font-size: 1em;
     } 

`

const SubTitleDark = styled(SubTitle)`
    color: black;
    font-weight: 500;
`

const LogoBigWrapper = styled.img`
    height: 265px;
    @media (max-width: 1000px) {
        display: none;
    }
`

export const BenefitsSection = ({ mainTitle, benefitsTitle, textTopLeft, textTopRight, textDownRight, firstBenefit, secondBenefit, thirdBenefit }) => (
    <Benefits>
        <SectionHeaderDark text={'E-Learning'} />
        <ContentWrapper>
            <Wrapper>
                <MainTitle>
                    {mainTitle}
                </MainTitle>
                <LogoBigWrapper src={LogoSvg} />
            </Wrapper>
            <Wrapper>
                <SubTitleDark>House 4 Innovation</SubTitleDark>
            </Wrapper>
            <Wrapper>
                <SubTitle>{textTopLeft}</SubTitle>
                <SubTitle>{textTopRight}</SubTitle>
            </Wrapper>
            <ColumnWrapper>
                <BenefitsTitle>{benefitsTitle}</BenefitsTitle>
                <SubTitle>{textDownRight}</SubTitle>
            </ColumnWrapper>
            <BenefitsWrapper>
                <BenefitComponent number={"01"} img={UpdateImg} title={firstBenefit.title} subTitle={firstBenefit.subTitle} />
                <BenefitComponent number={"02"} img={UpgradeImg} title={secondBenefit.title} subTitle={secondBenefit.subTitle} />
                <BenefitComponent number={"03"} img={InnovateImg} title={thirdBenefit.title} subTitle={thirdBenefit.subTitle} />
            </BenefitsWrapper>
        </ContentWrapper>
    </Benefits>
)

BenefitsSection.propTypes = {
    mainTitle: PropTypes.string,
    benefitsTitle: PropTypes.string,
    textTopLeft: PropTypes.string,
    textTopRight: PropTypes.string,
    textDownRight: PropTypes.string,
    firstBenefit: PropTypes.object,
    secondBenefit: PropTypes.object,
    thirdBenefit: PropTypes.object
}

Benefits.defaultProps = {
    mainTitle: "Peak \nperformance \nand innovations",
    benefitsTitle: "Benefits",
    textTopLeft: "Creative development + Innovation. Learn how to develop an Ecosystem for creativity and innovation.",
    textTopRight: "Strategy, structure, systems, culture - creativity and innovation is first and for most about people thinking and working smarter together.",
    textDownRight: "I am strategic, structured, systematic - a role model for what i want to achieve with what i do for my customers.",
}