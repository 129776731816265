import React from 'react'
import styled from 'styled-components'
import PropTypes from 'prop-types'
import RightBottomSvg from '../../assets/images/motivation/right-bottom.svg'
import { SectionHeader } from "../SectionHeader/SectionHeader";
import { MainHeading } from '../Shared/MainHeading';
import { device } from '../../constants/mediaQueries';

const Motivation = styled.section`
    min-height: 100vh;
    background-image: url(${RightBottomSvg});
    background-position: right bottom -1px;
    background-repeat: no-repeat;
    background-size: 230px;
    
    @media only screen and (max-width: 768px) {
      background-size: 110px;
    }

    background-color: #9D79FF;
`;


const Wrapper = styled.div`
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    justify-content: space-between;
    margin: auto auto;
    padding-bottom: 30px; 

    width: 95vw;
    max-width: 768px;
    @media ${device.tablet} {

    }
    @media ${device.laptopL} {
        max-width: 1024px;
        
    }

`;

const SubTitle = styled.p`
    margin: 0;
    margin-bottom: 40px;
    font-family: 'Work Sans', sans-serif;
    font-size: 1.7em;
    letter-spacing: -0.05em;
    color: #FFFFFF;
    
    @media all and (max-width: 767px) { 
        margin: 0.5em 0;
        max-width: 100%;
        font-size: 1em;
     } 
`;

const FooterText = styled.p`
    margin: 0;
    font-family: 'Work Sans', sans-serif;
    font-size: 1.3em;
    letter-spacing: -0.05em;
    color: rgba(255, 255, 255, 0.6);;
    
    @media all and (max-width: 767px) { 
        margin: 0.5em 0;
        max-width: 100%;
        font-size: 1em;
     } 
`


export const MotivationSection = ({ title, textLeft, textRight }) => (
    <Motivation>
        <SectionHeader text={'E-Learning'} />

        <Wrapper>
            <MainHeading>{title}</MainHeading>

            <SubTitle>{textLeft}</SubTitle>
            <FooterText>{textRight}</FooterText>
        </Wrapper>
    </Motivation>
);

MotivationSection.propTypes = {
    title: PropTypes.string.isRequired,
    textLeft: PropTypes.string.isRequired,
    textRight: PropTypes.string.isRequired
};

MotivationSection.defaultProps = {
    title: 'I help individuals, teams, and organizations to peak perfomance',
    leftText: 'Ambassadors, CEO’s to professionals footballers or any type of managers IT specialists',
    rightText: 'Architects, anyone - if you want to become better at what you do - try me.'
};
